.body-root{
    font-family: Muoto,-apple-system,BlinkMacSystemFont,Segoe UI,sans-serif !important;
    background-color: #f5f7fb;
}
.ant-layout-content{
    background-color: #f5f7fb !important;
}
.ant-layout-content, .ant-menu-title-content{
    font-family: 'Nunito', sans-serif !important;
}
.anticon-menu-fold{
    margin: 12px;
}
.banner-title{
    font-size:4em;
    padding:50px; 
    padding-top:20px; 
    padding-bottom:0px;
}
.banner-text-welcom{
    font-size:25px;padding:0px 50px;
}
.home-section-welcom{
    padding: 20px 0px;
    padding-bottom: 25px;
    background-color: #29743A;
    color: #fff;
}
.btn-full-width{
    width: 100%;
}
.main-icon-text-banner{
    color: #ffffff !important;
}
.main-icon-banner{
    font-size: 3rem;
    color: #ffffffb8 !important;
}
.card-banner{
    background-color: #ffffff57 !important;
    border-radius: 15px !important;
}
.bg-info-transparent{
    margin-right: 10px;
    background-color: #25cff236;
    color: #033540;
    font-weight: 600;
    border-color: #25cff236;
}
.group-item-cust:hover{
    background: #ffd43b !important;
    cursor: pointer;
    transition: all 400ms ease;
}
.group-item-cust{
    padding: 8px;
    font-size: 15px;
}
.float-right{
    float: right;
}
.badge-warning {
    color: #212529;
    background-color: #ffc107;
}
.header-text-cust{
    font-weight: 600;
    vertical-align: top;;
}
.list-icon{
    color: #339af0;
    font-size: 25px;
    margin-right: 15px;
}
.ant-menu-title-content {
    flex: none !important;
}
a{
    text-decoration: none !important;
}
.ant-upload-wrapper .ant-upload {
    width: 100%;
}
.ant-btn-icon-only{
    padding-bottom: 26px !important;
}
.ant-btn-icon-only span{
    vertical-align: middle !important;
}
.ant-layout-header {
    padding-inline: 0px !important;
}
.btn-banner{
    background: #ffffff5c;
    border-color: #fff0;
    color: #FFE70F;
}
.bglight{
    background-color: #f8f9fa;
}
.btn-primary {
    background: #109a2e;
    border-radius: 4px;
    border-color: #109a2e;
}
.btn-primary:hover, .btn-primary:active {
    background: #109a2ed4 !important;
    border-color: #109a2ed4 !important;
}
.nav-link {
    font-weight: 600;
    color:#000;
    cursor: pointer;
}
table{
    font-size: 14px;
}
table th{
    font-size: 16px;
}
.fc-event {
    border-radius: 10px;
    border: none;
}
.fc-scroller {
    background-color: #f2f2f2;
    /* Add any other styling properties as needed */
}
th .fc-col-header-cell{
    background-color: #29743a;
    border-radius: 8px 8px 0px 0px;
}
th .fc-scrollgrid-sync-inner a{
    color: #fff !important;
}
th .fc-scrollgrid-sync-inner{
    padding: 8px;
}
.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background: #2d8140;
}
.ant-menu-dark .ant-menu-item-selected {
    background-color: #236031;
}
.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):active {
    background-color: #236031;
}
.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-submenu-title:active {
    background-color: #065217 !important;
}
.ant-menu-submenu-title {
    background-color: #065217 !important;
    margin: 0 !important;
    border-radius: 0px !important;
    width: 100% !important;
}
.ant-menu-item-group-title{
    background-color: #19622a;
}
.ant-menu-item{
    margin-inline: 0px !important;
    margin-block: 0px !important;
    width: 100% !important;
    border-radius: 0px !important;
    background-color: #3b8d4e !important;
}
.ant-table-wrapper .ant-table-thead>tr>th{
    padding: 8px 8px;
}
.ant-table-wrapper .ant-table-tbody>tr>td{
    padding: 8px 8px;
}
.custom-spinner{
    width: 20px;
    height: 20px;
    color: #ffe70f;
}
.custom-checkbox {
    width: 18px; /* set the width */
    height: 18px; /* set the height */
}
.form-label {
    font-size: 16px;
}

circle {
    fill: steelblue;
  }
  
  .line-path {
    fill: maroon;
  }
  
  text {
    font-family: sans-serif;
  }
  
  .tick text {
    font-size: 15px;
    fill: #635F5D;
  }
  
  .tick line {
    stroke: #C0C0BB;
    display: none;
  }
  
  .axis-label {
    font-size: 3em;
    fill: #8E8883;
  }
  
  .title {
    font-size: 2em;
    fill: #635F5D;
  }

.report-title {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
}
.pie-chart-label {
    font-size: 15px;
    font-weight: 600;
    fill: white; /* Couleur du texte */
}
.pie-chart-legend{
    font-size: 15px;
    font-weight: 600;
}
.arc text {
    pointer-events: none;
}
.tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 5px;
    font-size: 12px;
    border-radius: 3px;
    z-index: 999;
}
.ant-select-disabled .ant-select-selector{
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #e9ecef !important;
    color: #212529 !important;
    border: 1px solid #ced4da !important;
    cursor: not-allowed !important;
}
.table-row-light {
    background-color: #ffffff;
}
.table-row-dark {
    background-color: #fbfbfb;
}
.mb3-new{
    margin-bottom: 5px !important;
}
.ant-upload-list-item {
    border-radius: 5px;
    background-color: #fff;
    padding: 20px 5px;
}
.ant-upload-icon .anticon-paper-clip {
    display: none;
}

/* Insert the Font Awesome icon using :before pseudo-element */
.ant-upload-icon:before {
    content: "\f0c6"; /* Unicode of the paperclip icon in Font Awesome */
    font-family: 'Font Awesome\ 5 Free'; /* Font Awesome font family */
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
/* Adjust the size of the Font Awesome icon */
.ant-upload-icon .anticon:before {
    font-size: 1em; /* Adjust the size as needed */
}
.ant-upload-list-item .ant-upload-list-item-done{
    display: block !important;
}
.ant-upload-icon{
    display: none !important;
}
.ant-upload-list-item-name{
    max-width: 300px;
}
.navbar-toggler{
    display: none !important;
}
.ant-steps .ant-steps-item-process .ant-steps-item-icon {
    background-color: #e9eaeb;
    border-color: #e9eaeb;
    color: #000 !important;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon {
    color: #000 !important;
}
